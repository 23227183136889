<script>
import omit from 'lodash.omit'
import api from '@/command/api'
import apiTool from '@/command/apiTool'
import { getAction } from '@/command/netTool'
import DetailFormGroup from '../../../components/DetailFormGroup'

const getChildren = function getChildren(children) {
  return children.map((e) => {
    return {
      ...e,
      label: e.name,
      value: e.code,
      children: e.children && e.children.length > 0 ? getChildren(e.children) : null,
    }
  })
}

export default {
  name: 'parkingLotDetail',
  data() {
    return {
      ...api.command.getState(),
      detail: {
        areaCode: [],
        address: '',
        lat: '',
        lon: '',
      },
      areaCode: [],
      // 区域类型 镇村
      villageCodeArr: [],
      jwdAyy: [],
    }
  },
  mounted() {
    const { id } = this.$route.query

    // 获取级联数据
    api.command.getRegionType.call(this, {
      url: '/api/region/threeTree',
      key: 'areaCode',
    })

    if (id) {
      api.command.getDetail
        .call(this, {
          url: `/farmCelebrityCountrySquire/detail?id=${id}`,
        })
        .then((result) => {
          this.detail.areaCode = [result.provinceCode, result.cityCode, result.districtCode]
          this.detail.dw = `${this.detail.lon}，${this.detail.lat}`
          this.$forceUpdate()
        })
    }
  },
  methods: {
    onOpenMap() {
      apiTool.openMap({
        title: '打开地图',
        value: this.jwdAyy,
        id: this.$route.query.id,
        onSuccess: (data) => {
          this.detail.lat = data.lat
          this.detail.lon = data.lng
          this.detail.dw = `${this.detail.lon}，${this.detail.lat}`
          let index = this.jwdAyy.findIndex((e) => e.id == data.id)
          let icon = new AMap.Icon({
            image: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            imageSize: new AMap.Size(20, 40),
          })
          let obj = {
            lng: data.lng,
            lat: data.lat,
            icon: icon,
            id: data.id,
          }
          if (index > -1) {
            this.jwdAyy.splice(index, 1, obj)
          } else {
            this.jwdAyy.push(obj)
          }
          getAction('/api/gao', {
            location: `${this.detail.lon},${this.detail.lat}`,
          }).then((res) => {
            let { address, codes } = res.data
            this.detail.areaCode = codes
            this.detail.address = address
            this.$forceUpdate()
          })
          this.$forceUpdate()
        },
      })
    },
    getForm1() {
      return {
        title: '基本信息',
        type: 'cardForm',
        data: [
          {
            name: '名称(15字符内)',
            type: 'input',
            cols: 24,
            key: 'name',
            props: {
              maxLength: 15,
            },
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            type: 'iconUpload',
            cols: 24,
            key: 'mapIcon',
            name: '地图图标',
            rules: [
              {
                required: true,
              },
            ],
            typeData: [
              {
                maxLength: 1,
                key: 'mapIcon',
              },
            ],
          },
        ].filter((e) => (e.display == undefined ? true : e.display)),
      }
    },
    getForm2() {
      return {
        title: '位置',
        type: 'cardForm',
        data: [
          {
            name: '经纬度 （例：120.184349，30.254460）',
            type: 'row',
            cols: 12,
            rules: [
              {
                required: true,
              },
            ],
            gutter: 5,
            children: [
              {
                type: 'input',
                cols: 21,
                key: 'dw',
                label: '经纬度',
                disabled: true,
                rules: [
                  {
                    required: true,
                    type: 'string',
                    message: '请输入经纬度',
                  },
                ],
              },
              {
                type: 'button',
                label: '定位',
                cols: 3,
                key: 'a1',
                props: {
                  type: 'primary',
                  icon: 'environment',
                  on: {
                    click: this.onOpenMap,
                  },
                },
              },
            ],
          },
          {
            name: '所在城市',
            type: 'row',
            cols: 12,
            rules: [
              {
                required: true,
              },
            ],
            gutter: 5,
            children: [
              {
                type: 'cascader',
                cols: 10,
                key: 'areaCode',
                label: '所在城市',
                typeData: this.areaCode,
                placeholder: '请选择所在城市',
                rules: [
                  {
                    required: true,
                    type: 'array',
                    message: '请选择所在城市',
                  },
                ],
              },
              {
                type: 'input',
                cols: 14,
                key: 'address',
                label: '详细地址',
                rules: [
                  {
                    required: true,
                    message: '请选择地址',
                  },
                ],
              },
            ],
          },
          {
            type: 'rich',
            key: 'content',
            cols: 24,
          },
        ],
      }
    },

    getSumbitData() {
      const codeText = ['province', 'city', 'district']
      let arr = document.getElementsByClassName('ant-cascader-picker-label')[0].innerHTML.split('/')
      const obj = {}
      arr.forEach((e, i) => {
        obj[codeText[i] + 'Name'] = e
        obj[codeText[i] + 'Code'] = this.detail.areaCode[i]
      })
      return obj
    },

    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          onClick: (data) => {
            const codeData = this.getSumbitData()
            let arr = data.dw.split('，')
            data.lon = arr[0]
            data.lat = arr[1]
            let obj = {
              ...data,
              ...codeData,
            }
            console.log(obj, 'obj')
            api.command.create
              .call(this, {
                url: `/farmCelebrityCountrySquire/save`,
                params: {
                  ...obj,
                },
                isPost: false,
              })
              .then(() => {
                this.$router.back()
              })
          },
        },
        {
          name: '取消',
          type: 'default',
          onClick: () => this.$router.back(),
        },
      ]
      return {
        left,
      }
    },
  },
  render() {
    return <DetailFormGroup form={this.detail} foot={this.getFoot()} data={[this.getForm1(), this.getForm2()]} />
  },
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
</style>
